<style lang="less" scoped>

.list-item {
  background-color: #333333;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  line-height: 1.5rem;
  font-size: 0.9rem;
  color: #dddddd;
  position: relative;
}

.title {
  font-size: 16px;
  color: #ffffff;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>

<template>
  <div style='position: relative;min-height: 835px;'>
    <div class="page">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onListLoad"
          class="list"
      >
        <div v-for="item in list" v-bind:key="item._id" class="list-item">
          <div class="title">
            {{ item.title }}
          </div>
          <div class="message">
            {{ item.message }}
          </div>
          <div class="created_at">
            {{ item.created_at }}
          </div>
          <div :style="{backgroundColor: item.bg}" class="dot"></div>
        </div>
      </van-list>

    </div>
    <VersionNum style="position:absolute;left:0;bottom:0;"></VersionNum>
  </div>

</template>
<script>
import {getNoticeList} from "../../api/notice";
import VersionNum from "../com/version_num";

export default {
  name: "notice",
  components:{VersionNum},
  data() {
    return {
      loading: false,
      p_loading: false,
      finished: false,
      list: [],
      query: {
        page_size: 10,
        page_num: 0
      },
    };


  },
  mounted() {
    // this.handleGetList()
  },
  methods: {
    handleGetList(){
      this.p_loading = true;
      getNoticeList({...this.query}).then((res)=> {
        let list = res.data.data_list.map(item => {
          let title = item.title || ''
          return {
            title: title,
            message: item.message,
            created_at: item.create_time,
            has_readed: !!item.is_read,
            _id: item._id,
            bg: title.includes('平') || title.includes('开') ? '#4bba41' :'orange',
          }
        })
        // eslint-disable-next-line no-debugger
        if(this.query.page_num === 1){
          this.list = list
        }else{
          this.list = [...this.list,...list]
        }
        // eslint-disable-next-line no-debugger
        this.loading = false
        this.p_loading = false
        this.finished = this.list.length >= res.total_count

      }).finally(() => {
        // eslint-disable-next-line no-debugger
        this.p_loading = false
        this.loading = false

      })
    },
    onListLoad(){
      // eslint-disable-next-line no-debugger
      // this.loading = false
      if(this.p_loading){
        return
      }
      this.query.page_num =  this.query.page_num +1
      this.handleGetList()
    }
  }
};
</script>
